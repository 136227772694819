.code-container {
  position: relative;

  border-radius: 3px;

  box-shadow: rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
  margin: 0px 0px 14px 0px;
  padding: 30px 0px;

  .code {
    z-index: 0;
    position: relative;

    // FIXME: get rid of all these !importants
    border: 0px !important;
    overflow-x: auto;
    // overflow-y: auto;

    margin-top: 0px !important;
    margin-right: 0px !important;
    margin-bottom: 0px !important;

    // SyntaxHighlighter style clone
    text-rendering: optimizeLegibility;
    overflow-wrap: break-word;
    white-space: pre;
    word-break: break-all;
    -webkit-font-smoothing: antialiased;
    text-size-adjust: 100%;
    font-stretch: 100%;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    code {
      white-space: pre;
    }

    &--underlay {
      font-family: $fontCode !important;
      color: transparent;
    }

    &--syntax {
      font-family: $fontCode !important;

      position: absolute;
      top: 30px;

      background-color: transparent !important;

      // We can hide code-syntax scrollbar while showing code-main scrollbar
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &--template {
      font-family: $fontCode !important;

      color: transparent !important;

      position: absolute;
      top: 30px;

      // z-index: 1;

      background-color: transparent !important;

      // We can hide code-syntax scrollbar while showing code-main scrollbar
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &--markdown {
      white-space: pre-wrap;
      word-wrap: normal;
      word-break: normal;
    }

    .highlight {
      display: inline-block;
      height: $highlightHeight + 0px;

      vertical-align: middle;

      background-color: #ffbf00;

      // Override selection styling
      &::selection {
        color: transparent; /* WebKit/Blink Browsers */
        background: transparent;
      }

      &::-moz-selection {
        color: transparent; /* Gecko Browsers */
        background: transparent;
      }
    }

    //////////////////////////////////////////////////////
    // CURSOR
    //////////////////////////////////////////////////////

    .highlight-0 {
      opacity: 1 !important;
      border-left-width: 2px;
      border-left-style: solid;
      border-left-color: rgba(0, 0, 0, 0.6) !important;
      margin-right: -2px;
      animation: blink-left 1s ease-in infinite;
    }

    .highlight-9007199254740991 {
      opacity: 1 !important;
      border-right-width: 2px;
      border-right-style: solid;
      border-right-color: rgba(0, 0, 0, 0.8) !important;
      margin-right: -2px;
      animation: blink-right 1s ease-in infinite;
    }

    .highlight-cursor-light {
      background-color: rgba(0, 0, 255, 0.2) !important;
    }

    .highlight-cursor-dark {
      background-color: rgba(36, 190, 133, 0.6) !important;
    }

    @keyframes blink-left {
      50% {
        border-left-color: rgba(255, 255, 255, 0);
      }
    }

    @keyframes blink-right {
      50% {
        border-right-color: rgba(255, 255, 255, 0);
      }
    }

    //////////////////////////////////////////////////////
    //////////////////////////////////////////////////////
  }

  .react-syntax-highlighter-line-number {
    &:hover {
      cursor: pointer;
      color: white;
      background: #24be85;
    }
  }
}
