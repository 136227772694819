.cp-dropdown {
  width: 100%;

  &--light {
    color: $neutralDisable;

    &:hover {
      color: rgba(0, 0, 0, 0.7) !important;
    }
  }

  // FIXME: Dark theme dropdown not perfect yet;
  &--dark {
    .ant-btn {
      // border: solid 1px #5e5e5e !important;
      background-color: #323435 !important;
      color: rgba(255, 255, 255, 0.5) !important;

      &:hover {
        color: rgba(255, 255, 255, 0.8) !important;
      }
    }
    // border: solid 1px #5e5e5e !important;
    background-color: #323435 !important;
    color: rgba(255, 255, 255, 0.5) !important;

    &:hover {
      color: rgba(255, 255, 255, 0.8) !important;
    }
  }

  &--dark.ant-dropdown-open {
    .ant-btn {
      color: rgba(255, 255, 255, 0.8) !important;
    }
  }

  .cp-button {
    &--light {
      color: $neutralDisable;
    }
  }
}

.ant-dropdown {
  box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.15) !important;
}
