.tests-menu {
  .ant-menu-submenu-selected > .ant-menu-submenu-title {
    color: rgba(0, 0, 0, 0.7);
  }

  .ant-menu-submenu-title {
    height: 30px !important;
    line-height: 30px !important;

    font-size: 12px !important;
    margin: 0px !important;
  }

  .ant-menu-item {
    height: 30px !important;
    line-height: 30px !important;
    font-size: 12px !important;
    margin: 0px !important;
  }
  .ant-menu-submenu-arrow {
    right: undefined;
    left: 10px;
  }

  .ant-dropdown-menu-item {
    padding-right: 20px !important;
  }

  &__files {
    .ant-menu-item {
      padding-left: 12px !important;
    }
  }
}

.tests__edit-definition {
  .CodeMirror {
    height: auto;
  }
}
