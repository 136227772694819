.pseudo-terminal {
  #pseudoterminal-body {
    font-family: $fontCode;
  }

  .blCJWW {
    height: 100%;
    min-height: 0;
    max-width: none;
  }

  .lfnIny {
    max-width: none;
  }

  .djXnpl {
    max-width: none;
  }

  &__run {
    display: flex;
    width: 72px;
    padding: 0px 8px;
    justify-content: space-evenly;

    line-height: 31px;

    font-weight: 700;
  }

  .ant-select-selection {
    background-color: transparent;
    color: rgb(151, 151, 151);
    border-color: rgb(151, 151, 151);

    .anticon {
      color: rgb(151, 151, 151);
    }
  }

  &--button {
    line-height: 31px;
    background-color: rgb(51, 51, 51);

    &:hover {
      color: white;
    }
  }
}
