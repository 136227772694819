.sider-menu {
  // max-height set by javascript
  overflow-y: auto;
  overflow-x: hidden;

  border-right: 0px;

  .ant-menu-item {
    width: 100% !important; // undoes an Ant hack (calc(100% + 1px))

    height: 44px !important;
    line-height: 44px !important;

    padding-left: 26px !important;
    padding-right: 40px !important;

    font-size: 12px !important;
    font-weight: 500 !important;

    &::after {
      border-right: 5px solid $brandPrimary !important;
    }
  }

  &--light {
    .ant-menu-item-selected {
      background-color: #f0fff7 !important;
      color: $brandPrimary;
    }
  }

  &--dark {
    .ant-menu-item-selected {
      background-color: #323435 !important;
      color: $brandPrimary;
    }
  }
}

.tests-menu--dark {
  .ant-table-cell {
    background: #18191c !important;
  }

  .ant-table-tbody {
    background: #18191c !important;
  }

  .ant-table-thead > tr > th {
    background: #18191c !important;
    color: $neutralDarkMainText !important;
  }

  .ant-table-header-column {
    color: $neutralDarkMainText !important;
  }

  .ant-table-row {
    color: $neutralDarkMainText !important;
  }

  .ant-table-row:hover {
    background: gray;
    color: $brandPrimary !important;
  }
}

// Submenu for folder directories
#file-menu {
  .sider-submenu {
    .ant-menu-submenu-title {
      height: 24px;
      line-height: 24px;

      padding-left: 15px;
      font-size: 12px;
      font-weight: 400;
      margin-top: 0px;
      margin-bottom: 0px;
      border-top: 1px solid #f4f4f4;
    }

    .ant-menu-item {
      margin-top: 0px;
      margin-bottom: 0px;
    }

    &--light {
      .ant-menu-submenu-title {
        background-color: #f9f9f9 !important;
      }
    }

    &--dark {
    }
  }
}

#file-menu {
  .sider-menu {
    .ant-menu-item {
      padding-left: 16px !important;
    }
  }
}

.submission-info__late-day-credits {
  width: 230px;
  margin: 4px 0px;

  .ant-alert {
    font-size: 12px;
  }
  .ant-alert.ant-alert-no-icon {
    padding: 2px 5px;
  }
}
