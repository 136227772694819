// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
}

html,
body {
  height: 100vh;
  width: 100vw;
  // overflow: hidden;
}

#root {
  margin: 0;
  height: 100%;
  width: 100%;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 * See: https://css-tricks.com/international-box-sizing-awareness-day/;
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}
