.badge {
  .ant-badge-count {
    box-shadow: 0 0 0 0px transparent;
  }

  &--standard {
    min-width: 33px;
    height: 21px;
  }

  &--small {
    .ant-badge-count {
      font-size: 10.5px;
      line-height: 16px;
      min-width: 24px;
      height: 16px;
    }
  }

  &--neutral {
    .ant-badge-count {
      background-color: $neutralSecondaryText;
    }
  }

  &--negative {
    .ant-badge-count {
      background-color: $actionRed;
    }
  }
  &--placeholder {
    .ant-badge-count {
      color: transparent;
    }
  }

  &--positive {
    .ant-badge-count {
      background-color: $actionGreen;
    }
  }

  &--faded {
    opacity: 0.7;
  }

  &--normal {
    opacity: 1;
  }
}
