.point-input-group {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  max-width: 120px;
  margin: 0px auto;

  &--dark {
    .ant-input-number {
      border-color: #57595c;
      z-index: 10;

      &:active {
        border-color: $brandPrimary;
        z-index: 11;
      }
      &:focus {
        border-color: $brandPrimary;
        z-index: 11;
      }
      &:hover {
        border-color: $brandPrimary;
        z-index: 11;
      }
    }

    .ant-btn {
      border-color: #57595c;
      z-index: 10;

      &:active {
        border-color: $brandPrimary;
        z-index: 11;
      }
      &:focus {
        border-color: $brandPrimary;
        z-index: 11;
      }
      &:hover {
        border-color: $brandPrimary;
        z-index: 11;
      }
    }
  }
}

.cp-point-input {
  .ant-input-number-handler-wrap {
    visibility: hidden;
    width: 0px;
  }

  &--default {
    width: auto;
  }

  &--small {
    width: 72px !important;
    .ant-input-number {
      width: 34px;
      font-size: 12px;
    }

    .ant-input-number-input {
      padding: 0px 8px;
    }

    .anticon {
      font-size: 9px !important;
      margin-right: 0px !important;
      min-width: 9px !important;
    }

    .ant-btn {
      padding: 0px 4px !important;
      height: 24px;
      line-height: 10px;
    }

    .ant-btn-icon-only {
      width: auto;
    }
  }
}
