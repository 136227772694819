/* Animation CSS helpers */
.module--codeReview {
  transform: translateY(0) perspective(4000px) rotateY(-5deg);
}

// Highlighted active rows in in admin tables
.animation-row-active {
  background-color: rgba(46, 205, 133, 0.2) !important;
}
// Sert grade border to none
.simpleCodeBox {
  border: 0px !important;
}
// Add a box shadow to grade menu
.SimpleGradeMenu {
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.11);
}

// Loading image, button, and testimonial animation
@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-in-bottom {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
  }
}

.landing__heroImg {
  animation: fade-in 1.4s;
}

.landing__heroButtons {
  animation: fade-in 1.4s;
}

.landing__testimonials {
  animation: fade-in-bottom 1.4s;

  .BrainhubCarousel__arrows {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    padding: 16px;

    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
    }
  }
}

#APIExample {
  // API Docs Reference styling, including arrow animation on move
  .apiexample {
    &__docs {
      .apiexample__docs__arrow {
        transform: translateX(0px);
        transition: transform 0.3s ease;
      }
      &:hover {
        color: #7bd8b5;
        .apiexample__docs__arrow {
          transform: translateX(5px);
          transition: transform 0.3s ease;
        }
      }
      &:focus {
        color: #7bd8b5;
      }
    }
    &__header {
      color: white;
      transition: 0.3s;
      &__icon {
        color: white;
        transition: 0.3s;
      }
      &:hover {
        color: #7bd8b5;
        transition: 0.3s;

        .apiexample__header__icon {
          color: #7bd8b5;
          transition: 0.3s;
        }
      }
    }
    &__link {
      transition: 0.3s;
      &:hover {
        color: rgba(255, 255, 255, 0.8) !important;
        transition: 0.3s;
      }
    }
  }
}

#WhyUse {
  .whyUse__link {
    transition: 0.3s;
    color: black;
    &:hover {
      color: #24be85 !important;
      transition: 0.3s;
    }
  }
}

// Testimonial highlights
.codePost-highlight {
  mark {
    background-color: #d3f2e7 !important;
    border-radius: 3px !important;
    padding: 2px 5px 1px 5px !important;
    font-weight: 600 !important;
    color: rgba(0, 0, 0, 0.55);
  }
}

// Hero "code review" highlight
.codePost-title-highlight {
  font-family: PT Mono, monospace;
  font-weight: 400;
  mark {
    background-color: #d3f2e7 !important;
    border-radius: 4px !important;
    padding: 3px 8px 2px 8px !important;
    color: #24be85;
  }
}

.codePost-highlight-new {
  mark {
    background-color: #f5f5f5 !important;
    border-radius: 3px !important;
    padding: 2px 5px 1px 5px !important;
    font-weight: 600 !important;
    color: rgba(0, 0, 0, 0.55);
  }
}

// Hero "code review" highlight
.codePost-title-highlight-new {
  font-family: PT Mono, monospace;
  font-weight: 400;
  mark {
    background-color: #f7f7f7 !important;
    border-radius: 4px !important;
    padding: 3px 8px 2px 8px !important;
    color: #8a8a8a;
  }
}

// Why Panel codeMirror
.bad-codemirror {
  .CodeMirror {
    font-family: monaco, Consolas, Lucida Console, mono;
    font-size: 10px;
    line-height: 1.8;
    font-weight: 500;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.08);
    // need to fix the height because adding a perspective elongates the codebox
    height: 400px;
    overflow-y: hidden;
    padding: 10px;

    &-gutters {
      background-color: white;
    }
  }
  .CodeMirror-scroll {
    overflow-y: hidden !important;
  }
}

// API Panel codeMirror
.api-codemirror {
  .CodeMirror {
    font-family: monaco, Consolas, Lucida Console, mono;
    line-height: 1.8;
    font-weight: 500;
    height: auto;
    padding: 10px;
    font-size: 11px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  }
  @media only screen and (max-width: 500px) {
    max-height: 300px;
    overflow-y: auto;
  }
}

// Required specificity to make ant panel header in API section have a white arrow
#APIExample .ant-collapse-header {
  color: white;
}
.bevel {
  box-shadow: 8px 8px 22px 0 hsla(0, 0%, 84.7%, 0.25), 0 0 2px 0 rgba(0, 0, 0, 0.15),
    10px 25px 20px 0 rgba(0, 0, 0, 0.05);
  border-radius: 5;
  overflow: hidden;

  &--left {
    transform: scale(0.9) perspective(2000px) rotateY(10deg) rotateX(-1deg) rotate(-1deg);
  }

  &--right {
    transform: scale(0.9) perspective(2000px) rotateY(-10deg) rotateX(1deg) rotate(1deg);
  }
}

.landing__link {
  color: #24be85;
  transition: 0.3s ease;

  &:hover {
    color: #aaa;
    transition: 0.3s ease;
  }
  &:focus {
    color: #aaa;
    transition: 0.3s ease;
  }
}

#panel .landing__link {
  color: #24be85;
  transition: 0.3s ease;

  .landing__link__arrow {
    transform: translateX(0px);
    transition: transform 0.3s ease;
  }

  &:hover {
    color: #aaa;
    .landing__link__arrow {
      transform: translateX(5px);
      transition: transform 0.3s ease;
    }
  }
  &:focus {
    color: #aaa;
    transition: 0.3s ease;
  }
}

//************************** LANDING FLOW CHART **************************
.flowNode {
  transition: 0.3s ease;
  &:hover {
    transform: scale(1.3);
  }
}

@-webkit-keyframes move-arrows {
  to {
    -webkit-mask-position: 11px 0;
    mask-position: 11px 0;
  }
}
@keyframes move-arrows {
  to {
    -webkit-mask-position: 11px 0;
    mask-position: 11px 0;
  }
}
@-webkit-keyframes move-arrows-delayed {
  from {
    -webkit-mask-position: 200px 0;
    mask-position: 200px 0;
  }
  to {
    -webkit-mask-position: 290px 0;
    mask-position: 290px 0;
  }
}
@keyframes move-arrows-delayed {
  from {
    -webkit-mask-position: 200px 0;
    mask-position: 200px 0;
  }
  to {
    -webkit-mask-position: 290px 0;
    mask-position: 290px 0;
  }
}

.flowArrow {
  animation: move-arrows-delayed 1s linear infinite;
  content: '';
  position: absolute;
  width: 110px;
  width: calc(202px - 72px - 10px * 2);
  height: 8px;
  -webkit-mask: url(../../img/landing/arrows-delayed.svg);
  mask: url(../../img/landing/arrows-delayed.svg);
  -webkit-transition-property: opacity, -webkit-transform;
  transition-property: opacity, -webkit-transform;
  transition-property: opacity, transform;
  transition-property: opacity, transform, -webkit-transform;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.slider-text {
  font-weight: 500;
  color: rgba(0, 0, 0, 0.9);
  transition: all 0.3s;
  &--active {
    font-weight: 500;
    color: #24be85 !important;
    transition: all 0.3s;
  }
  &--inactive {
    font-weight: 500;
    color: #aaaaaa !important;
    transition: all 0.3s;
  }
}

.ant-slider-dot {
  margin-left: -6px !important;
  top: -4px !important;
  height: 12px !important;
  width: 12px !important;
}

.annotation-image {
  z-index: 1000;
  transform: scale(0.8);
  transition: all 0.3s;

  &--active {
    z-index: 2000;
    transform: scale(1.4);
    transition: all 0.3s;
  }
}

.annotation-image {
  z-index: 1000;
  transform: scale(0.8);
  transition: all 0.3s;

  &--active {
    z-index: 2000;
    transform: scale(1.4);
    transition: all 0.3s;
  }
}

.autograder-image {
  z-index: 1000;
  transform: scale(0.6);
  transition: all 0.3s;

  &--active {
    z-index: 2000;
    transform: scale(1);
    transition: all 0.3s;
  }
}

@mixin cpLink($name) {
  color: #aaaaaa;
  .#{$name}__arrow {
    transform: translateX(0px);
    transition: transform 0.3s ease;
    margin-left: 5px;
  }
  &:hover {
    transform: scale(1.02);
    color: #7bd8b5;
    .#{$name}__arrow {
      transform: translateX(5px);
      transition: transform 0.3s ease;
      margin-left: 5px;
    }
  }
  &:focus {
    color: #7bd8b5;
  }
}
#Hero {
  .demo-link {
    @include cpLink('demo-link');
  }
}

#Testimonials {
  .testimonials-link {
    @include cpLink('testimonials-link');
  }
}
