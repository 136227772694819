.cp-logo {
  font-family: $fontLogo;
  font-size: 25px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: 0px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;

  &__highlight {
    font-weight: 800;
  }
}
