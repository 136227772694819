.log-viewer {
  &--code-mirror {
    height: 100%;
  }

  &--preview {
    position: relative;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    height: 100px;
    cursor: pointer;
    overflow-y: hidden;
    font-size: 12px;
  }

  &--inspect {
    position: relative;
    border: 1px solid #eaeaea;
    border-radius: 4px;
    cursor: default;
    overflow-y: auto;
    font-size: 12px;
  }

  &__mask {
    position: relative;
    bottom: 7em;
    height: 7em;
    background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    z-index: 100;
  }

  &__view-more {
    position: absolute;
    bottom: 10px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 999;
    width: 120px;
    border-radius: 16px;
    border: 0px solid transparent;
    background-color: #24be85;
    font-weight: 0;
    color: white;
    line-height: 28px;
    text-align: center;
  }
}
