.keyboard-shortcuts > .ant-tabs-card > .ant-tabs-content {
  margin-top: -16px;
  color: rgba(255, 255, 255, 0.65);
}

.keyboard-shortcuts > .ant-tabs-card > .ant-tabs-content > .ant-tabs-tabpane {
  background: rgb(33, 35, 37);
  padding: 16px;
}

.keyboard-shortcuts > .ant-tabs-card > .ant-tabs-bar {
  border-color: rgba(255, 255, 255, 0.65);
}

.keyboard-shortcuts > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab {
  border-top-color: rgb(33, 35, 37) !important;
  border-right-color: rgb(33, 35, 37) !important;
  border-left-color: rgb(33, 35, 37) !important;
  border-bottom-color: transparent !important;
  background: transparent !important;
  padding: 0px 24px !important;
  color: rgba(255, 255, 255, 0.65);
}

.keyboard-shortcuts > .ant-tabs-card > .ant-tabs-bar .ant-tabs-tab-active {
  border-top-color: rgba(255, 255, 255, 0.65) !important;
  border-right-color: rgba(255, 255, 255, 0.65) !important;
  border-left-color: rgba(255, 255, 255, 0.65) !important;
  border-bottom-color: rgb(33, 35, 37) !important;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
  background: rgb(33, 35, 37);
}

.keyboard-shortcuts-drawer {
  .anticon-close {
    color: rgba(255, 255, 255, 0.65) !important;
    vertical-align: 6px !important;
  }
}

.keyboard-shortcuts {
  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-auto-flow: row;
    grid-gap: 8px 40px;
    justify-items: center;
  }

  &__shortcut {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 280px;
  }

  &__keys {
    display: flex;
    justify-content: space-between;
  }

  &__key--slash {
    display: flex;
    height: 25px;
    font-size: 16px;
    margin: 0px;
    padding: 4px;
    align-items: bottom;
    justify-content: center;
  }

  &__key {
    display: flex;
    height: 25px;
    font-size: 11px;
    border: 1px solid rgba(255, 255, 255, 0.65);
    margin: 4px;
    padding: 8px;
    align-items: center;
    justify-content: center;
  }
}
