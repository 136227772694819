.rubric-menu {
  // max-height set using javascript
  overflow-y: auto;
  overflow-x: hidden;

  border-right: 0px !important;

  .ant-menu-sub {
    background-color: transparent !important;
  }

  .ant-menu-submenu-title {
    width: 100%; // undoes an Ant hack

    height: 33px !important;
    line-height: 32px !important;

    font-weight: 500;
    font-size: 12px;

    padding-left: 0px !important;

    margin: 0px !important;

    position: relative;
  }

  .ant-menu-item {
    width: 100%; // undoes an Ant hack

    margin-bottom: 4px !important;
    height: auto !important;
    line-height: 15.75px !important;
    letter-spacing: -0.05px !important;

    padding: 4px 10px 4px 26px !important;

    overflow: visible;
    white-space: pre-wrap;

    .ant-input {
      font-size: 12px;
      margin-right: 8px;
    }

    .rubric-row {
      &--active {
        &:hover {
          color: $brandPrimary !important;
        }
      }

      &--inactive {
        &:hover {
          .overlay {
            opacity: 1;
          }
          color: transparent;
          text-shadow: rgba(0, 0, 0, 0.5) 0px 0px 5px;
        }

        .overlay {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: black;
          opacity: 0;
        }
      }

      &--editing {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
      }
    }
  }

  &__info {
    display: table;
    white-space: pre-wrap;

    > div {
      &:nth-of-type(1) {
        display: table-cell;
        min-width: 100px;
        font-weight: 600;
      }

      &:nth-of-type(2) {
        display: table-cell;
        max-width: 300px;
        line-height: 10px;
      }
    }
  }
}

.search {
  &--light {
    input {
      background-color: #fff;
      color: rgba(0, 0, 0, 0.7);
    }
  }

  &--dark {
    .ant-input-affix-wrapper {
      background-color: #18191b !important;
    }

    .ant-input-group-addon {
      background-color: #323435 !important;
    }
    input {
      background-color: #18191b !important;
      color: rgba(255, 255, 255, 0.9);
    }
  }
}

@keyframes slide-in {
  from {
    bottom: -50px;
  }
  to {
    bottom: 0px;
  }
}

@keyframes slide-out {
  from {
    bottom: 0px;
  }
  to {
    bottom: -50px;
  }
}

#rubric-menu-controls {
  display: none;
  width: 100%;
  height: 50px;
  border-top: 1px solid #ececec;
  padding: 5px;
  position: absolute;
}

#rubric-menu-controls.slide-in {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  animation: slide-in 0.5s forwards;
}

#rubric-menu-controls.slide-out {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  animation: slide-out 1s forwards;
}
