@keyframes wiggle {
  0% {
    transform: rotate(0deg);
  }
  30% {
    transform: rotate(-8deg);
  }
  60% {
    transform: rotate(8deg);
  }
  90% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

#submission-status-toggle.wiggle {
  animation: wiggle 0.6s infinite;
}
